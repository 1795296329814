<template>
  <div class="account-form">
    <div class="cars-list" v-loading="loading">
      <div
        class="cars-item"
        v-for="(item, index) in list"
        :key="index"
        @click="clickUrl(item)"
      >
        <img
          class="cars-item-img"
          :src="require(`@/assets/images/card${item.ID}.png`)"
        />
        <div class="cars-item-name">
          {{ item.Name }}X{{ item.Amount }}<br /><span>完成任务</span>
        </div>
      </div>
      <div class="card-tips" v-if="list.length === 0 && !loading">暂无数据</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      loading: true
    };
  },
  methods: {
    clickUrl(data) {
      console.log("data", data);
      this.$emit("changeActiveIndex");
    },
    async getlist() {
      const data = await this.$http.get("/api/v1/Card/Stats");
      let response = JSON.parse(data.request.response);
      if (response.status === 200 && response.response) {
        console.log("response", response);
        this.list = response.response;
        this.loading = false;
      }
    }
  },
  computed: {},
  components: {},
  async created() {
    await this.getlist();
  }
};
</script>
<style lang="scss" scoped>
.account-form {
  width: 100%;

  .cars-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .cars-item {
      flex-shrink: 0;
      width: 214px;
      height: 104px;
      border-radius: 10px;
      margin-right: 22px;
      position: relative;
      cursor: pointer;
      margin-bottom: 20px;

      .cars-item-img {
        width: 214px;
        height: 104px;
      }

      .cars-item-name {
        position: absolute;
        color: #fff;
        font-size: 14px;
        top: 34px;
        left: 18px;
        cursor: pointer;

        span {
          font-size: 12px;
          margin-top: 3px;
        }
      }
    }
  }
}

.card-tips {
  width: 100%;
  text-align: center;
}
</style>
