<template>
  <div class="center-setting">
    <el-menu
      :default-active="activeIndex"
      class="setting-menu"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="account">卡券</el-menu-item>
      <el-menu-item index="third">卡券记录</el-menu-item>
    </el-menu>
    <div :class="['card', { card1: activeIndex === 'account' }]">
      <AccountForm
        v-show="activeIndex === 'account'"
        @changeActiveIndex="changeActiveIndex"
        ref="AccountForm"
      />
      <ThirdPartBinding
        @changeAccount="changeAccount"
        v-show="activeIndex === 'third'"
      />
    </div>

    <div class="card card2" v-show="activeIndex === 'account'">
      <p>
        1、 翻倍卡：使用翻倍卡
        可以获得你完成的商业调查2倍奖励（信用值加成除外），例如：您完成A调查获得基础奖励500金币，信用值加成50金币，如果使用翻倍卡，您将额外获得500金币。<br />
        翻倍卡获取途径：①、通过参与速调吧答卷“寻宝专区”，关卡随机获得。②、通过参与速调吧不定期的活动获得。
      </p>
      <p>
        2、
        抽奖卡：使用抽奖卡，可以参与速调吧推出的“速调吧幸运抽奖100%中奖”活动，可以随机获得抽奖奖励。<br />
        抽奖卡获取方法：①、通过参与速调吧答卷“寻宝专区”，关卡随机获得。②、通过参与速调吧不定期的活动获得。
      </p>
      <p>
        3、
        免审卡：使用免审卡，可以立即收到当前正在审核的微信红包订单，无需等待。<br />
        免审卡获取方法：①、通过参与速调吧答卷“寻宝专区”，关卡随机获得。②、通过参与速调吧不定期的活动获得。
      </p>

      速调吧不断推出各种类型的卡券，在速调吧答卷的同时也能获得更多的乐趣，精彩预告：即将推出“复活卡”，复活卡：使用复活卡，可以恢复答卷人工审核拒绝的金币和信用值。<br />

      <strong> 注意：本活动只在新版速调吧中推出</strong>
    </div>
  </div>
</template>
<script>
import AccountForm from "./account-form";
import ThirdPartBinding from "./thirdpart-binding";
export default {
  data() {
    return {
      activeIndex: "account"
    };
  },
  methods: {
    handleSelect(v) {
      this.activeIndex = v;
    },
    changeActiveIndex() {
      this.activeIndex = "third";
    },
    changeAccount() {
      this.$refs.AccountForm.getlist();
    }
  },
  created() {
    if (this.$route.query.tab) {
      this.activeIndex = this.$route.query.tab;
    }
  },
  components: {
    AccountForm,
    ThirdPartBinding
  }
};
</script>
<style lang="scss" scoped>
.center-setting {
  .setting-menu {
    border-radius: 10px;
    overflow: hidden;
  }
}

.card2 {
  color: #3d3d3d;

  p {
    color: #3d3d3d;
    font-size: 16px;
    margin-bottom: 15px;
  }
}
.card1 {
  padding-bottom: 0;
}
</style>
