<template>
  <div class="account-form">
    <div class="screen-box">
      <div class="title">使用状态</div>
      <el-select v-model="used" @change="changeUsed" placeholder="请选择">
        <el-option
          v-for="item in useOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="title title2">卡券类别</div>
      <el-select v-model="cardType" @change="changeUsed" placeholder="请选择">
        <el-option
          v-for="item in cardTypeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <el-table
      :stripe="true"
      :data="list"
      v-loading="loading"
      style="width: 100%;margin-top:20px"
      element-loading-background="rgba(0, 0, 0, 0)"
    >
      <el-table-column
        :show-overflow-tooltip="true"
        prop="CardTypeName"
        label="卡券名称"
        width="150"
      >
      </el-table-column>
      <el-table-column
        :show-overflow-tooltip="true"
        prop="Dateline"
        label="获取时间"
        width="200"
      >
        <template slot-scope="scope">
          {{ scope.row.Dateline | getDataTime }}
        </template>
      </el-table-column>
      <el-table-column
        :show-overflow-tooltip="true"
        prop="UpdateTime"
        label="使用时间"
        width="200"
      >
        <template slot-scope="scope">
          {{ scope.row.UpdateTime | getDataTime }}
        </template>
      </el-table-column>
      <el-table-column
        :show-overflow-tooltip="true"
        prop="Used	"
        label="状态"
        width="150"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.Used">已使用</span>
          <span v-else>未使用</span>
        </template>
      </el-table-column>
      <el-table-column
        :show-overflow-tooltip="true"
        prop="Describe"
        label="说明"
      >
      </el-table-column>
      <el-table-column :show-overflow-tooltip="true" label="操作">
        <template slot-scope="scope">
          <span
            v-if="
              scope.row.Used ||
                scope.row.CardType === 2 ||
                scope.row.CardType === 4
            "
            >——</span
          >
          <el-button
            @click="changeOperate(scope.row)"
            class="primary"
            v-else-if="scope.row.CardType === 1"
            size="small"
            >前去抽奖</el-button
          >
          <el-button
            @click="changeOperate(scope.row)"
            class="primary"
            v-else
            size="small"
            >使用</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="mt-20">
      <el-pagination
        background
        layout="prev, pager, next,total"
        :total="pagination.totalSize"
        :page-size="pagination.pageSize"
        :current-page="pagination.currentPage"
        @current-change="changePage"
      >
      </el-pagination>
    </div>

    <div class="zhezhao" v-if="fragmentShow"></div>

    <div class="jl-box" v-if="fragmentShow && fragmentType === 1">
      <div class="jl-box-card">
        <img class="bgCard1" src="@/assets/images/bgCard1.png" />
        <img
          class="close"
          src="@/assets/images/reward/close.png"
          @click="changeFragmentShow"
        />
        <div class="identifier">编号{{ ProjectNumber }}</div>
        <div class="bgicon-box">
          <div class="bgIcon1-box">
            <img class="bgIcon1" src="@/assets/images/bgIcon1.png" />
            <span>+{{ Points }}</span>
          </div>
          <img class="bgicon2" src="@/assets/images/bgicon2.png" />
          <div class="bgIcon3-box">
            <img class="bgicon3" src="@/assets/images/bgicon3.png" />
            <span>+{{ Points * 2 }}</span>
            <div class="X2-box">
              <img class="X2" src="@/assets/images/X2.png" />
            </div>
          </div>
        </div>

        <div class="btn-have" @click="goUrl('/center/gold-record')">
          我知道了
        </div>

        <div class="jl-tips">可在「金币历史」中查看</div>
      </div>
    </div>

    <div class="jl-box" v-if="fragmentShow && fragmentType === 2">
      <div class="jl-box-card">
        <img class="bgCard1" src="@/assets/images/bgCard2.png" />
        <img
          class="close"
          src="@/assets/images/reward/close.png"
          @click="changeFragmentShow"
        />
        <div class="identifier">编号{{ OrderNumber }}</div>
        <div class="bgicon-box2">
          <img class="bgCard2-bg" src="@/assets/images/bgCard2-bg.png" />
        </div>

        <div class="btn-have" @click="goUrl('/center/exchange-record')">
          我知道了
        </div>

        <div class="jl-tips">可在「兑换历史」中查看</div>
      </div>
    </div>

    <div class="jl-box" v-if="fragmentShow && fragmentType === 3">
      <div class="jl-box-card">
        <img class="bgCard1" src="@/assets/images/bgCard3.png" />
        <img
          class="close"
          src="@/assets/images/reward/close.png"
          @click="changeFragmentShow"
        />
        <div class="identifier">编号{{ ProjectNumber }}</div>
        <div class="bgicon-box3">
          <img class="bgCard3-bg" src="@/assets/images/bgCard3-bg.png" />

          <div class="bgCard3-name">
            <span class="bgCard3-name-left" v-if="Points"
              >金币+{{ Points }}</span
            >
            <span v-if="CreditValue">信用值+{{ CreditValue }}</span>
          </div>
        </div>

        <div class="btn-have" @click="goUrl('/center/credit')">
          我知道了
        </div>

        <div class="jl-tips jl-tips2">可在「我的信用值/金币历史」中查看</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  filters: {
    getDataTime(data) {
      let time = "-";
      if (data) {
        time = data.slice(0, 16);
      }
      return time;
    }
  },
  data() {
    return {
      useOptions: [
        {
          value: null,
          label: "全部"
        },
        {
          value: true,
          label: "已使用"
        },
        {
          value: false,
          label: "未使用"
        }
      ],
      cardTypeOptions: [
        {
          value: 1,
          label: "抽奖卡"
        },
        {
          value: 2,
          label: "集碎片卡"
        },
        {
          value: 3,
          label: "免审卡"
        },
        {
          value: 4,
          label: "寻宝卡"
        },
        {
          value: 5,
          label: "翻倍卡"
        },
        {
          value: 6,
          label: "复活卡"
        }
      ],
      list: [],
      loading: true,
      pagination: {
        pageSize: 15,
        totalSize: 0,
        currentPage: 1
      },
      used: null,
      cardType: null,
      fragmentShow: false,
      fragmentType: 1,

      ProjectNumber: "",
      Points: "",
      OrderNumber: "",
      CreditValue: ""
    };
  },
  methods: {
    changePage(v) {
      this.getList(v);
    },
    async getList(v) {
      this.loading = true;
      const data = await this.$http.get(`/api/v1/Cards`, {
        params: {
          page: v || this.pagination.currentPage,
          size: this.pagination.pageSize,
          used: this.used,
          cardType: this.cardType
        }
      });
      let response = JSON.parse(data.request.response);
      if (response.status === 200 && response.response) {
        console.log("response", response);
        this.list = response.response.data;
        this.pagination.totalSize = response.response.dataCount;
      }

      this.loading = false;
    },
    changeUsed() {
      this.pagination = {
        pageSize: 15,
        totalSize: 0,
        currentPage: 1
      };
      this.getList();
    },
    changeFragmentShow() {
      this.fragmentShow = false;
      this.ProjectNumber = "";
      (this.Points = ""), (this.OrderNumber = ""), (this.CreditValue = "");
    },
    async setCards(id, type) {
      const data = await this.$http.post(`/api/v1/Cards/${id}`, null, {
        params: {
          id
        }
      });
      let response = JSON.parse(data.request.response);
      if (response.status === 200 && response.response) {
        console.log("response", response);
        this.fragmentType = type;
        this.fragmentShow = true;
        this.getList();

        if (response.extend) {
          this.Points = response.extend.Points;
          this.ProjectNumber = response.extend.ProjectNumber;
          this.OrderNumber = response.extend.OrderNumber;
          this.CreditValue = response.extend.CreditValue;
        }
        this.$emit("changeAccount");
      } else {
        this.$message.warning(response.msg);
      }
    },
    changeOperate(data) {
      console.log("data", data);
      switch (data.CardTypeName) {
        case "抽奖卡":
          this.$router.push("/prize-draw");
          break;
        case "翻倍卡":
          console.log("翻倍卡");
          this.setCards(data.ID, 1);
          break;
        case "免审卡":
          console.log("免审卡");
          this.setCards(data.ID, 2);
          break;
        case "复活卡":
          console.log("复活卡");
          this.setCards(data.ID, 3);
          break;
      }
    },
    goUrl(path) {
      this.$router.push(path);
    }
  },
  computed: {},
  created() {
    this.getList();
  }
};
</script>
<style lang="scss" scoped>
.el-pagination {
  text-align: center;
  margin-top: 10px;
}

.screen-box {
  display: flex;
  align-items: center;

  .title {
    color: #000;
    font-size: 16px;
    margin-right: 20px;
  }

  .title2 {
    margin-left: 20px;
  }
}

.to-use {
  cursor: pointer;
  color: #3399ff;
}

.primary {
  background: #3399ff;
  color: #fff;
}

.zhezhao {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000000;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
}

.jl-box {
  position: fixed;
  z-index: 1000001;
  width: 349px;
  height: 400px;
  top: 40%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  .jl-box-card {
    width: 100%;
    height: 100%;
  }

  .identifier {
    position: absolute;
    top: 145px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #c23d11;
    font-size: 16px;
  }

  // background-color: #fff;
  .bgCard1 {
    width: 100%;
    height: 100%;
  }

  .close {
    position: absolute;
    right: -26px;
    top: 23px;
    width: 26px;
    height: 26px;
    cursor: pointer;
  }

  .bgicon-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    top: 180px;

    .bgIcon1-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #c23d11;
      font-size: 18px;
      font-weight: bolder;
    }

    .bgIcon3-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #c23d11;
      font-size: 18px;
      font-weight: bolder;
      position: relative;

      .X2-box {
        position: absolute;
        top: -8px;
        right: -15px;
        width: 29px;
        height: 16px;
        background: linear-gradient(311deg, #ff6a55 0%, #ffb330 100%);
        border-radius: 8px 8px 8px 0px;
        display: flex;
        align-items: center;
        justify-content: center;

        .X2 {
          width: 16px;
        }
      }
    }

    .bgIcon1 {
      width: 54px;
      height: 54px;
    }

    .bgicon2 {
      width: 45px;
      height: 42px;
      margin: 0 6px 30px 6px;
    }

    .bgicon3 {
      width: 81px;
      height: 69px;
    }
  }

  .bgicon-box2 {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 180px;

    .bgCard2-bg {
      width: 80px;
      margin-top: 14px;
    }
  }

  .bgicon-box3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 180px;

    .bgCard3-bg {
      width: 100px;
      margin-top: 0px;
    }

    .bgCard3-name {
      display: flex;
      justify-content: center;
      color: #c23d11;
      font-size: 16px;
      margin-top: 15px;

      .bgCard3-name-left {
        margin-right: 19px;
      }
    }
  }

  .btn-have {
    width: 150px;
    height: 42px;
    line-height: 42px;
    background: linear-gradient(270deg, #fcbc3c 0%, #ff5f15 100%);
    box-shadow: inset 1px 1px 5px 0px rgba(255, 255, 255, 0.4),
      0px 4px 7px 0px #ffe185;
    border-radius: 21px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }

  .jl-tips {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #d9d9d9;
    font-size: 14px;
  }
  .jl-tips2 {
    bottom: -20px;
  }
}
</style>
